(function () {
    let observer = null;

    function lazyLoadImages(wrapper = document.body, isSwiper = false) {
        const selector = isSwiper ? '.swiper-slide-visible img.lazyload' : 'img.lazyload';
        const images = wrapper.querySelectorAll(selector);

        if (!("IntersectionObserver" in window)) {
            images.forEach(img => preloadImage(img));
            return;
        }

        if (!observer) {
            observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        preloadImage(entry.target);
                        observer.unobserve(entry.target);
                    }
                });
            });
        }

        images.forEach(img => observer.observe(img));
    }

    function preloadImage(img) {
        if (img.classList.contains('loaded') || img.classList.contains('error')) return;

        const originalSrc = img.getAttribute('data-src');
        if (!originalSrc) return console.warn('data-src не указан для изображения:', img);

        img.src = originalSrc;
        if (img.hasAttribute('data-srcset')) {
            img.srcset = img.getAttribute('data-srcset');
        }
        img.decoding = "async";
        img.classList.add('loaded');

        img.onerror = () => img.classList.add('error');
    }

    window.imagesLazyLoad = wrapper => lazyLoadImages(wrapper, false);
    window.imagesLazyLoadSwiper = wrapper => lazyLoadImages(wrapper, true);
    document.dispatchEvent(new Event("lazyloadReady"));
})();